<template>
  <div>
    <div class="devices-animation" v-if="animationStyles.length">
      <div :style="animationStyles[0]" />
      <div :style="animationStyles[1]" />
      <div :style="animationStyles[2]" />
      <div :style="animationStyles[3]" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeviceAnimation',
  data() {
    return {
      currentAnimationDevice: 'tablet',
      animationStyles: [],
    };
  },
  props: {
    transformToBall: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    transformToBall() {
      this.setAnimationStyles();
    },
    currentAnimationDevice() {
      this.setAnimationStyles();
    },
  },
  methods: {
    setAnimationStyles() {
      if (this.transformToBall) {
        this.animationStyles = [
          {
            top: '239px',
            left: '171px',
            borderRadius: '60px',
            backgroundColor: 'black',
            width: '12px',
            height: '12px',
            zIndex: '1',
          },
          {
            top: '239px',
            left: '171px',
            borderRadius: '60px',
            backgroundColor: 'black',
            width: '12px',
            height: '12px',
            zIndex: '1',
          },
          {
            top: '239px',
            left: '171px',
            borderRadius: '60px',
            backgroundColor: 'black',
            width: '12px',
            height: '12px',
            zIndex: '1',
          },
          {
            top: '239px',
            left: '171px',
            borderRadius: '60px',
            backgroundColor: 'black',
            width: '12px',
            height: '12px',
            zIndex: '2',
          },
        ];
        return;
      }

      let animationStyles = [];
      switch (this.currentAnimationDevice) {
        case 'desktop':
          animationStyles = [
            {
              top: '0px',
              left: '0px',
              borderRadius: '6px',
              backgroundColor: 'black',
              width: '358px',
              height: '172px',
              zIndex: '2',
            },
            {
              top: '4px',
              left: '4px',
              borderRadius: '6px',
              backgroundColor: 'white',
              width: '350px',
              height: '164px',
              zIndex: '2',
            },
            {
              top: '172px',
              left: '170px',
              borderRadius: '0px',
              backgroundColor: 'black',
              width: '12px',
              height: '20px',
              zIndex: '-1',
            },
            {
              top: '192px',
              left: '143px',
              borderRadius: '2px',
              backgroundColor: 'black',
              width: '66px',
              height: '16px',
              zIndex: '-1',
            },
          ];
          setTimeout(() => {
            this.currentAnimationDevice = 'laptop';
          }, 2000);
          break;
        case 'laptop':
          animationStyles = [
            {
              top: '0px',
              left: '62px',
              borderRadius: '6px',
              backgroundColor: 'black',
              width: '236px',
              height: '126px',
            },
            {
              top: '6px',
              left: '68px',
              borderRadius: '6px',
              backgroundColor: 'white',
              width: '224px',
              height: '114px',
            },
            {
              top: '135px',
              left: '61px',
              borderRadius: '2px',
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              width: '236px',
              height: '30px',
              zIndex: '2',
              transform: 'perspective(10px) rotateX(1.6deg)',
            },
            {
              top: '124px',
              left: '51px',
              borderRadius: '6px',
              backgroundColor: 'black',
              width: '256px',
              height: '60px',
              transform: 'perspective(10px) rotateX(1.6deg)',
            },
          ];
          setTimeout(() => {
            this.currentAnimationDevice = 'phone';
          }, 2000);
          break;
        case 'phone':
          animationStyles = [
            {
              top: '40px',
              left: '144px',
              borderRadius: '6px',
              backgroundColor: 'black',
              width: '70px',
              height: '109px',
            },
            {
              top: '44px',
              left: '148px',
              borderRadius: '6px',
              backgroundColor: 'white',
              width: '62px',
              height: '100px',
            },
            {
              top: '60px',
              left: '214px',
              borderRadius: '60px',
              backgroundColor: 'black',
              width: '2px',
              height: '14px',
              zIndex: '-1',
            },
            {
              top: '40px',
              left: '164px',
              borderRadius: '60px',
              backgroundColor: 'black',
              width: '30px',
              height: '8px',
            },
          ];
          setTimeout(() => {
            this.currentAnimationDevice = 'tablet';
          }, 2000);
          break;
        case 'tablet':
          animationStyles = [
            {
              top: '40px',
              left: '74px',
              borderRadius: '6px',
              backgroundColor: 'black',
              width: '174px',
              height: '109px',
            },
            {
              top: '44px',
              left: '78px',
              borderRadius: '6px',
              backgroundColor: 'white',
              width: '166px',
              height: '101px',
            },
            {
              top: '64px',
              left: '255px',
              borderRadius: '60px',
              backgroundColor: 'black',
              width: '4px',
              height: '14px',
              zIndex: '-1',
            },
            {
              top: '68px',
              left: '254px',
              borderRadius: '60px',
              backgroundColor: 'black',
              width: '6px',
              height: '74px',
              zIndex: '-1',
            },
          ];
          setTimeout(() => {
            this.currentAnimationDevice = 'desktop';
          }, 2000);
          break;
        default:
          break;
      }
      this.animationStyles = animationStyles;
    },
  },
  created() {
    this.setAnimationStyles();
  },
};
</script>

<style lang="scss" scoped>
  $animationLength: 0.5s;

  // .is-ball {
  //   border: 9px solid white;
  // }

  .devices-animation {
    height: 220px;
    width: 358px;
    position: relative;

    & > div {
      position: absolute;
      transition:
        top $animationLength ease,
        left $animationLength,
        width $animationLength,
        height $animationLength,
        transform $animationLength,
        border-radius $animationLength,
        background-color $animationLength;
      z-index: 1;
    }
  }
</style>
