<template>
  <div id="nav">
    <div class="logo">more! it-solutions GmbH</div>
    <div class="links">
      <router-link to="/">Home</router-link>
      <router-link to="/contact">Kontakt</router-link>
      <router-link to="/imprint">Impressum</router-link>
    </div>
  </div>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: inherit;
}

body,
html {
  box-sizing: border-box;
  margin: 0px;
}

#nav {
  max-width: 1200px;
  user-select: none;
  padding: 30px 4px;
  padding-top: 30px;
  display: flex;
  margin-bottom: 80px;
  flex-direction: column;
  align-items: center;
  margin: auto;

  .logo {
    font-size: 18px;
    cursor: default;
    user-select: none;
    margin: auto;
    margin-bottom: 20px;
    font-weight: bold;
  }
}

.links {
  display: flex;

  a {
    text-decoration: none;
    padding: 4px 8px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.75);

    &:not(:last-child) {
      margin-right: 20px;
    }

    &.router-link-exact-active {
      border-bottom: 2px solid #458DFF;
    }
  }
}

@media (min-width: 1024px) {
  #nav {
    padding-top: 40px;
    flex-direction: row;
    align-items: unset;

    .logo {
      margin-left: unset;
      margin-right: auto;
      margin-bottom: unset;
      font-weight: 600;
    }
  }
}
</style>
