<template>
  <div
    class="product-showcase"
    :class="{ 'reversed': this.reversed }"
  >
    <transition :name="reversed ? 'fade-right' : 'fade-left'">
      <div
        v-if="inViewport"
        class="image-controls-wrapper"
      >
        <div class="image-wrapper">
          <img
            class="image"
            :src="`/${activeImage.image}`"
          >
        </div>
        <div class="image-controls">
          <div
            v-for="(image, index) in images"
            :key="image.image"
            class="image-control"
            :class="{ 'image-control--active': image.image === activeImage.image }"
            @click="setActiveImage(index)"
          />
        </div>
      </div>
    </transition>
    <transition :name="reversed ? 'fade-left' : 'fade-right'">
      <div
        v-if="inViewport"
        class="texts"
      >
        <h2 class="product-name">{{ productName }}</h2>
        <div class="description">{{ activeImage.description }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeImageIndex: 0,
      inViewport: false,
    };
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    productName: {
      type: String,
      required: true,
    },
    reversed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    activeImage() {
      return this.images[this.activeImageIndex];
    },
  },
  methods: {
    setActiveImage(index) {
      this.activeImageIndex = index;
    },
  },
  mounted() {
    const options = {
      root: null,
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.inViewport = true;
        }
      });
    }, options);

    observer.observe(this.$el);
  },
};
</script>

<style lang="scss" scoped>
.product-showcase {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 60px 10px;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    align-items: unset;
    height: 320px;
    flex-direction: row;
    padding: 80px 0px;
    box-sizing: content-box;
  }
}

.image-wrapper {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.texts {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (min-width: 1024px) {
    margin-left: 60px;
  }
}

.reversed .texts {
  margin-left: unset;

  @media (min-width: 1024px) {
    margin-right: 60px;
  }
}

.product-name {
  margin-bottom: 8px;
}

.image {
  width: 320px;

  @media (min-width: 1024px) {
    height: 320px;
    width: unset;
  }

  &-wrapper {
    border-radius: 4px;
    overflow: hidden;
    flex-shrink: 0;
  }

  &-controls-wrapper {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;

    @media (min-width: 1024px) {
      width: 620px;
      flex-direction: row;
    }

    @media (min-width: 1100px) {
      width: 700px;
    }
  }
}

.reversed .image-controls-wrapper {
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row-reverse;
  }
}

.reversed {
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row-reverse;
  }
}

.image-controls {
  display: flex;
  margin-top: 6px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1024px) {
    flex-direction: column;
    margin-top: unset;
    margin-left: 20px;
    margin-right: unset;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.reversed .image-controls {
  @media (min-width: 1024px) {
    margin-left: unset;
    margin-right: 20px;
  }
}

.image-control {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 60px;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  margin: 6px;
  cursor: pointer;

  @media (min-width: 1024px) {
    margin: 4px 0px;
  }

  &:hover {
    background-color: lighten(#458DFF, 20%);
  }

  &--active {
    background-color: #458DFF;
  }
}

.fade-right-enter-active,
.fade-right-leave-active,
.fade-left-enter-active,
.fade-left-leave-active {
  transition: all .4s;
}

.fade-left-enter-from,
.fade-left-leave-to {
  @media (min-width: 1100px) {
    transform: translateX(-80px);
    opacity: 0;
  }
}

.fade-right-enter-from,
.fade-right-leave-to {
  @media (min-width: 1100px) {
    transform: translateX(80px);
    opacity: 0;
  }
}
</style>
