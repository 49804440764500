<template>
  <div class="home">
    <div class="entry">
      <div>
        <div>
          <h1>Desktop.</h1>
          <h1>Laptop.</h1>
          <h1>Smartphone.</h1>
          <h1>Tablet.</h1>
        </div>
        <div class="entry-subtext">
          <p>Unsere Software-Produkte werden von tausenden Benutzern
          täglich auf Geräten verschiedenster Größen genutzt.</p>
          <p>Wir entwickeln für Sie maßgeschneiderte Programme, Apps, Webseiten und Schnittstellen.</p>
        </div>
      </div>
      <device-animation />
    </div>
    <h2>Unsere Software-Produkte</h2>
    <div class="showcases">
      <product-showcase
        v-for="(product, index) in products"
        :key="product.name"
        :product-name="product.name"
        :images="product.images"
        :reversed="!!(index % 2)"
      />
    </div>
  </div>
</template>

<script>
import DeviceAnimation from '../components/DeviceAnimation.vue';
import ProductShowcase from '../components/ProductShowcase.vue';

export default {
  components: {
    DeviceAnimation,
    ProductShowcase,
  },
  name: 'Home',
  data() {
    return {
      products: [
        {
          name: 'Ratsinformationssystem',
          images: [
            {
              image: 'ris1.png',
              description: 'Schnelle und einfache Veröffentlichung von Sitzungen und Dokumenten für Bürger und Anwender',
            },
            {
              image: 'ris2.png',
              description: 'Der integrierte PDF-Viewer garantierte eine komfortable Betrachtung der Dokumente, ohne dass Benutzer sich die Dokumente herunterladen müssen',
            },
            {
              image: 'ris3.png',
              description: 'Übersichtliche Darstellung aller Sitzungsinformationen auf einer Seite; kein Hin- und Herspringen zwischen Tabs',
            },
          ],
        },
        {
          name: 'Sitzungsdienst',
          images: [
            {
              image: 'sd1.png',
              description: 'Der Sitzungsdienst bietet Verwaltungen und Unternehmen einfache und gleichzeitig umfangreiche Möglichkeiten, Sitzungen zu planen, dazugehörige Dokumente digitial zur Verfügung zu stellen und nach dynamisch anpassbaren Kriterien abrechnen zu lassen',
            },
            {
              image: 'sd2.png',
              description: 'Detaillierte Vorlagenerfassung vor der Erstellung einer Sitzung. Bereits hier lassen sich umfangreiche Sichtbarkeiten festlegen, um Unbefugten die Einsicht auf vertrauliche Informationen zu verwähren.',
            },
          ],
        },
        {
          name: 'DiPolis - Digitales Politikinfosystem',
          images: [
            {
              image: 'app1.png',
              description: 'Die mobile App für papierlose Gremienarbeit, verfügbar unter iOS, Android und Windows. Bereits deutschlandweit genutzt! Bleiben Sie mit Ihren Dokumenten und Sitzungen stets auf dem Laufenden.',
            },
            {
              image: 'app2.png',
              description: 'Übersichtliche Darstellung aller sitzungsrelevanten Informationen und Dokumente.',
            },
            {
              image: 'app3.png',
              description: 'Umfangreiche Möglichkeit, Ihre Dokumente mit händischen Anmerkungen zu versehen und die Dokumente über all Ihre Geräte zu synchronisieren.',
            },
          ],
        },
        {
          name: 'RedLab',
          images: [
            {
              image: 'redlab1.png',
              description: 'Ein individuell zugeschnittenes Backend-System zum Administrieren von Kundenständen auf einem eigens gehosteten Webserver. Vom Erstellen, Klonen und Updaten von Kundenständen, bis zur Administrierung von Lizenzen und Auswertungen von Datenbank-Einträgen über einen SQL-Editor, sind hier alle Funktionalitäten enthalten, um das Administrieren von Kundenständen stark zu vereinfachen.',
            },
          ],
        },
        {
          name: 'Wasserstandszähler',
          images: [
            {
              image: 'wasserstand1.png',
              description: 'Die Bürger der Verbandsgemeinde Wirges übermitteln bereits seit mehreren Jahren den Wasserstand bequem online. Per Post werden individuelle Zugangsdaten verschickt, über die sich ein Bürger anmelden und den Wasserstand online übermitteln kann.',
            },
          ],
        },
        {
          name: 'OParl-API',
          images: [
            {
              image: 'oparl1.png',
              description: 'Eine Standard-API für den Zugang zu öffentlichen Inhalten in kommunalen Ratsinfosystemen. Die API-Results können von Entwicklern aus aller Welt für möglichst viele verschiedene Zwecke eingesetzt werden (z.B. Apps, Webseiten etc.)',
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  h2 {
    text-align: center;
    margin-bottom: -90px;
  }
}
.entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 26px;
  line-height: 36px;
  max-width: 1000px;
  margin: auto;
  flex-direction: column-reverse;
  padding: 0px 20px;

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: unset;
  }

  &-subtext {
    font-size: 20px;
    line-height: 28px;
    max-width: 450px;
    font-weight: 400;
    margin-left: 4px;
    color: rgba(0, 0, 0, 0.9);
    display: none;

    @media (min-width: 1024px) {
      display: block;
    }
  }
}

.showcases {
  max-width: 1200px;
  margin: auto;

  @media (min-width: 1024px) {
    margin: 0px 20px;
    margin-top: 60px;
  }

  @media (min-width: 1300px) {
    margin: auto;
    margin-top: 60px;
  }
}
</style>
